.header {
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px rgba(0, 0, 87, 0.25);
  margin-bottom: 10px;
  padding: 0 20px;
  position: fixed;
  //position: absolute;
  top: 0;
  width: 100vw;
  z-index: 11;
  display: flex;
  align-items: center;
}

.header__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1180px) {
  .header__wrapper {
    max-width: 780px;
  }
}

.header__title__logo__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.header__logo {
  height: 42px;
  width: 42px;
  object-fit: contain;

}

.header__title__container {
  margin-left: 3px;
}

.header__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 19px;
  color: #0F163F;
}

.header__title__yellow {
  color: #EEBB1E;
  font-weight: 800;
}

.header__menu__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__bell__container {
  margin-right: 70px;
}

.header__menu {
  display: none;
}

.header__list__container {
  display: flex;
  align-items: center;
}

.header__list {
  margin-left: 30px;
}

.header__list__link {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  position: relative;
  cursor: pointer;

  &:hover {
    color: #000057;
  }
}

.header__list__link__disabled {
  opacity: 0.5;
  pointer-events: none;
}

.active__route {
  font-weight: 700;
  color: #000057;
}

.header__list__span__promo {
  position: absolute;
  top: -15px;
  right: -17px;
  background: url("../assets/Login.Registr/promoNew.png") center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.bell__icon {
  animation: 5s linear 2s infinite forwards bell;
  cursor: pointer;
  position: relative;
}

.bell__icon__number {
  position: absolute;
  top: -1px;
  right: -5px;
}

@keyframes bell {
  0% {
    transform: rotate(0deg);
  }
  4% {
    transform: rotate(-15deg);
  }
  8% {
    transform: rotate(0deg);
  }
  12% {
    transform: rotate(15deg);
  }
  16% {
    transform: rotate(-3deg);
  }
  19% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media screen and (min-width: 780px) {
  .header__menu {
    display: block;
  }
  .burger__container {
    display: none;
  }
  .header__bell__container {
    margin-right: 30px;
  }
}