.form__container {
  padding: 0 10px;
}

.registration-login {
  background: #FCFAE9;
  border-radius: 16px;
  padding: 20px 35px 30px 35px;
  width: 100%;
  max-width: 390px;
  height: auto;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
}

.section__choose__container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 20px;
}

.section__choose__register {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #000057;
  position: relative;
  cursor: pointer;
  transition:  all 0.3s ease-out;
  &.active {
    color: #60A1EC;

  }
  &.fb{
    color: #FCFAE9;
  }
}
.section__choose__register__line{
  position: absolute;
  bottom: -10px;
  left: -10px;
  background-color: #000057;
  height: 2px;
  width: 130px;
  opacity: 0.4;
  &.fb{
    background-color: #FCFAE9;
  }
  &.active{
    background-color: #60A1EC;
  }
}
.section__choose__login {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #000057;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  transition:  all 0.3s ease-out;
  &.active {
    color: #60A1EC;
  }
  &.fb{
    color: #FCFAE9;
  }
}
.section__choose__login__line{
  content: "";
  position: absolute;
  bottom: -10px;
  left: -45px;
  background-color: #000057;
  height: 2px;
  width: 130px;
  opacity: 0.4;
  &.fb{
    background-color: #FCFAE9;
  }
  &.active{
    background-color: #60A1EC;
  }

}
.registration-login__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  color: #000057;
  text-align: center;
}

.input__container {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.label__inp {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 23px;
  color: #000057;
  margin-bottom: 15px;
}

.input {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 0, 87, 0.5) !important;
  background: inherit;
  //border: none;
  border-top: hidden !important;
  border-left: hidden !important;
  border-right: hidden !important;
  border-bottom: 1px solid black !important;
  position: relative;
  padding: 6px 0 6px 32px;
}

textarea:focus, input:focus, input:active, input:visited, input:hover, input:target {
  outline: none;
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
}

input:focus-visible {
  //outline: none;
  outline: none;
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  background-color: inherit !important;
}

.input.is-invalid {
  border-color: #dc3545;
  background-image: url("../assets/Login.Registr/error-icon.png");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid ~ .invalid__feedback {
  display: block;
  position: absolute;
  bottom: -20px;
}

.invalid__feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.input__name__icon {
  position: absolute;
  bottom: 9px;
  left: 5px;
  background: url("../assets/Login.Registr/user.png") center center no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.input__email__icon {
  position: absolute;
  background: url("../assets/Login.Registr/email.png") center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 15px;
  bottom: 9px;
  left: 5px;
}

.input__password__icon {
  position: absolute;
  bottom: 9px;
  left: 5px;
  background: url("../assets/Login.Registr/lock.png") center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 20px;
}

.input__visible__icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
}

.input__nonevisible__icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
}

//.input__repeatPassword__icon {
//  position: absolute;
//  bottom: 9px;
//  left: 5px;
//  background: url("../assets/Login.Registr/lock.png") center center no-repeat;
//  background-size: contain;
//  width: 16px;
//  height: 20px;
//}

.registration-login__btn__container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registration-login__btn__continue {
  background: #60A1EC;
  box-shadow: 0px 4px 15px rgba(96, 161, 236, 0.3);
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #FFFFFF;
  border: none;
  width: 100%;
  height: 55px;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
}
.login__reset__password{
  margin:10px;
}
.registration-login__reset__password {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #000057;
  opacity: 0.6;
  margin: 11px;
  &.fb{
    color: #FCFAE9;
  }
}

.registration-login__btn__container__text {
  margin: 11px 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 87, 0.5);
}

.registration-login__text__acc {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
  color: rgba(0, 0, 87, 0.5);
  margin: 30px 0 11px 0;
}

.registration-login__link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000057;
}


.registration-login__btn__google {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 55px;
  color: #000000;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding-left: 25px;
}

.registration-login__btn__google__text {
  margin: 0 22px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

//.google__icon {
//  position: absolute;
//  top: 16px;
//  left: 40px;
//  background: url("../assets/Login.Registr/google-icon.png") center center no-repeat;
//  background-size: contain;
//  width: 21px;
//  height: 21px;
//}

.google__icon__img {
  width: 21px;
  height: 21px;
  object-fit: contain;
}

.registration-login__checkbox__container {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}

input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -3px;
  left: -3px;
  background-color: #e9e9e9;
  border-radius: 6px;
}

input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -3px;
  left: -3px;
  background: #60A1EC;
  box-shadow: 0px 4px 4px rgba(114, 60, 234, 0.15);
  border-radius: 6px;
}

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: url("../assets/Login.Registr/checkWhite.png") center center no-repeat;
  background-size: contain;
  position: absolute;
  top: 1px;
  left: 1px;
}


.registration-login__checkbox__text {
  margin-left: 13px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #000057;
  opacity: 0.7;
  cursor: pointer;
}


@media screen and (max-width: 410px) {
  .registration-login {
    width: 96%;
  }
}

