.pwa__install__container {
  //position: fixed;
  position: absolute;
  top: 50px;
  background: #FCFAE9;
  box-shadow: 0px 2px 0px rgba(0, 0, 87, 0.25);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  z-index: 100;
  transition: all 1s ease-in-out;
  &.none{
    top: -150px;
  }
}

.pwa__install__logo__container {
  display: flex;
  align-items: center;
  max-width: 410px;
}

.pwa__install__img {
  width: 45px;
  height: 45px;
  object-fit: contain;
  background: #FFFFFF;
  box-shadow: 0px 2.36842px 4.73684px rgba(0, 0, 0, 0.25);
  border-radius: 5.92105px;
}

.pwa__install__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 88.4%;
  color: #EEBB1E;
}

.pwa__install__title__color {
  color: #0F163F;
}
.pwa__install__text{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 88.4%;
  color: #000057;
  margin-left: 12px;
}
.pwa__install__btn {
  background: #7BDA59;
  box-shadow: 0px 4px 8px rgba(123, 218, 89, 0.4);
  border-radius: 6px; border: none;
  width: 100%;
  height: 38px;
  font-family: 'Montserrat',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 88.4%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 12px;
  cursor: pointer;
  max-width: 410px;
}

.pwa__install__cross {
  position: absolute;
  right: 5px;
  top: 5px;
  font-weight: 700;
  font-size: 22px;
  color: #000057;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1440px) {
  .pwa__install__cross{
    right: 25px;
  }
}