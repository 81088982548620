.main{
  flex: 1 1 auto;
  padding-top:60px;
  //min-height: 80%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

