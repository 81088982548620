.card__telegram {
  order: 999;
  width: 100%;
  max-width: 320px;
  background: url("../assets/telegram/telergamBg.png") center center no-repeat, #29A9EB;
  background-size: cover;
  box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.card__telegram__img {
  margin-bottom: 10px;
  width: 68px;
  height: 68px;

}

.card__telegram__text__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 20px;
}

.card__telegram__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin-right: 5px;
}

.card__telegram__text__icon {
  width: 21px;
  height: 20px;
}

.card__telegram__btn {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(266.89deg, #FF6363 11.18%, #FFB74A 91.19%),
  linear-gradient(180deg, #5EEC9F 0%, #21BF73 100%),
  linear-gradient(180deg, #7CEC8E 0%, #4EDB65 100%), #FFFFFF;
  box-shadow: 0px 4px 8px rgba(103, 146, 255, 0.49);
  border-radius: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #29A9EB;
  width: 100%;
  height: 58px;
  border: none;
  cursor: pointer;
}
