.unsubscribe__container {
  background: #FCFAE9;
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px;
  max-width: 400px;
  margin: 12px auto;
}

.unsubscribe__block__container {
  overflow: hidden;
}

.unsubscribe__title {
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #000057;
}

.unsubscribe__subtitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #000057;
  opacity: 0.6;
}
.input__container__unsubscribe{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.label__inp__unsubscribe{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #000057;
  margin-bottom: 15px;
}
.input__unsubscribe {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 0, 87, 0.5) !important;
  background: inherit;
  border-top: hidden !important;
  border-left: hidden !important;
  border-right: hidden !important;
  border-bottom: 1px solid #000057 !important;
  position: relative;
  padding: 6px 0 6px 32px;
}

.input__tel__icon {
  position: absolute;
  bottom: 9px;
  left: 5px;
  background: url("../assets/Unsubscribe/telephone.png") center center no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.unsubscribe__button {
  background: #60A1EC;
  box-shadow: 0px 4px 15px rgba(96, 161, 236, 0.3);
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #FFFFFF;
  width: 100%;
  border: none;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .unsubscribe__container{
    padding: 20px;
    width: 94%;
  }
  .unsubscribe__button{
    margin-top: 20px;
  }
}
@media screen and (max-width: 350px) {
  .input__unsubscribe {
    font-size: 16px;
  }
}
@media screen and (max-width: 330px) {
  .unsubscribe__button{
    height: 50px;
    font-size: 18px;
  }
}