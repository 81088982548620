/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 15px !important;
  top: 10px !important;
  display: none !important;
}
//
.bm-burger-button {
  z-index: 1200 !important;
  display: none !important;
}
//
/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
.hamburger__container{
  background: #FFFFFF;
  z-index: 1300;
}

.hamburger-react {
  z-index: 1200;
}

@media screen and (min-width:780px)  {
  .hamburger-react{
    display: none;
  }
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
//*/
.bm-menu-wrap {
  position: relative;
  height: 100%;
  top: 0;
}

///* General sidebar styles */
.bm-menu {
  background: #FFFFFF;
}
//
///* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

///* Individual item */
.bm-item {
  display: inline-block;
}
//
///* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
//
///* Individual item */
.bm-item {
  display: inline-block;
  padding: 0 20px 10px;
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 0.2s;
}
//
.bm-item:hover {
  color: #ffffff;
}

.burger__top__container {
  background: #FFFFFF;
  box-shadow: 0px 2px 0px rgba(0, 0, 87, 0.25);
  padding: 19px 20px;
  height: 50px;
  -webkit-tap-highlight-color: inherit!important;
  &:focus-visible ,&:visited, &:hover,&:active,&:focus,&:target{
    outline: none!important;
  }
}

.burger__top__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 15px;
  color: #000057;
}
.burger__links__container {

}

.burger__link__wrapper {
  margin-top: 22px;
  display: block;
  text-decoration: none;
}
a:focus-visible {
  outline: none!important;
}
.burger__link {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 21px;
  text-align: center;
  color: #000057;
  margin-left: 11px;
  display: inline;
}

.burger__link__btn {
  border: none;
  background: inherit;
}

.burger__link__new {
  background: #EA0C0C;
  border-radius: 4px;
  padding: 3px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #FFFFFF;
}
