.layout {
  background: radial-gradient(348.05% 99.45% at 50% 0%, #C0C8E2 0%, #E9D6EF 51.56%, #F2D1D1 100%);
  height: auto;
  display: flex;
  flex-direction: column;
}
.layout__svg{
  background: url("../assets/layout/bg.png") center center repeat;
  position: fixed;
  top: 0;
  width: 100vw;
  height:100vh;
}
@media screen and (max-width:850px) {
  .layout {
    background-size: cover;
  }
}
@media screen and (min-height: 700px) {
  .layout {
    min-height: 100vh;
  }
}

.title__bg {
  background: url("../assets/layout/bgTitle.png") center center no-repeat;
  background-size: cover;
  width: 320px;
  height: 70px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title__rozdil {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  text-transform: uppercase;
  color: #000057;
  margin-left: 10px;
}

@media screen and (max-width: 340px) {
  .title__bg {
    width: 290px;
  }

  .title__rozdil {
    font-size: 23px;
    line-height: 23px;
  }
}